import { roundNumber } from '@pretto/bricks/core/utility/formatters'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'

import { ROUND_VALUE } from './constantValues'

export const toLocale = (amount, suffix = '', withDecimal) => {
  let n = roundNumber(amount, ROUND_VALUE).toLocaleString('fr')
  if (withDecimal) {
    // eslint-disable-next-line prefer-const
    let [whole, decimal = ''] = n.split(',')
    switch (decimal.length) {
      case 0:
        decimal = '00'
        break
      case 1:
        decimal += '0'
        break

      default:
        break
    }
    n = [whole, decimal].join(',')
  }
  return `${n} ${suffix}`
}

export const formatItem = numbers => {
  const item = {}
  Object.entries(numbers).forEach(([key, value]) => {
    Object.assign(item, { [key]: roundNumber(value, ROUND_VALUE).toLocaleString('fr') })
  })
  return item
}

export const objToArray = (obj, prefix = '') => {
  const elements = []
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      elements.push(...objToArray(value, key))
    } else {
      elements.push(`prefill[${prefix ? `${prefix}.` : ''}${key}]=${value}`)
    }
  })
  return elements
}

export const objToPath = obj => {
  const elements = objToArray(obj)
  const path = encodeURI(`https://app.staging.pretto.fr/project/purchase/introduction?${elements.join('&')}`)
  return path
}

const hoursPerDay = 24
const minutesPerDay = 1440
const secondsPerDay = 86400

const trafficRange = [
  { from: 7, to: 21, traffic: 88 },
  { from: 22, to: 6, traffic: 12 },
]
const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }

    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
const now = new dayjs()
const hour = now.hour()
const minute = now.minute()
const second = now.second()
const baseDelay = 1000
const delayDuration = 45
const percent = 100

export const useCount = ({ baseNumber, dailyNumber, lastUpdate }) => {
  const [count, setCount] = useState(baseNumber)
  const traffic = trafficRange.find(({ from, to }) => hour >= from && hour <= to).traffic
  const incrementPerSecond = (dailyNumber * traffic) / percent / secondsPerDay

  useEffect(() => {
    const lastDate = dayjs(lastUpdate)
    const diff = now.diff(lastDate, 'day') - 1
    const hourIncrement = ((dailyNumber * traffic) / percent / hoursPerDay) * hour
    const minuteIncrement = ((dailyNumber * traffic) / percent / minutesPerDay) * minute
    const secondIcrement = ((dailyNumber * traffic) / percent / secondsPerDay) * second
    const increment = diff * dailyNumber + hourIncrement + minuteIncrement + secondIcrement
    setCount(count + increment)
  }, [])

  useInterval(() => {
    setCount(count + incrementPerSecond * delayDuration)
  }, baseDelay * delayDuration)
  return Math.round(count)
}
