import temporal from '@pretto/bricks/core/utility/temporal'
import MiniFormComponent from '@pretto/bricks/website/simulators/components/MiniForm'
import IndebtednessRatePage from '@pretto/bricks/website/simulators/pages/IndebtednessRatePage'
import LoanSmoothingPage from '@pretto/bricks/website/simulators/pages/LoanSmoothingPage'
import NotaryFeesResultsPage from '@pretto/bricks/website/simulators/pages/NotaryFeesResultsPage'
import PaymentResultsPage from '@pretto/bricks/website/simulators/pages/PaymentResultsPage'
import PinelPage from '@pretto/bricks/website/simulators/pages/PinelPage'
import PrepaymentPage from '@pretto/bricks/website/simulators/pages/PrepaymentPage'
import PtzResultsPage from '@pretto/bricks/website/simulators/pages/PtzResultsPage'
import TaegResultsPage from '@pretto/bricks/website/simulators/pages/TaegResultsPage'

import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import { Fragment, memo } from 'react'

import { SIMULATORS_IMAGES } from '../../../config'
import { formatSimulators, isValueEmpty, objToPath, trackAction } from '../../../utilities'
import PrintPage from '../PrintPage'
import LABELS from '../config'
import * as FIELDS from '../config/fields'
import NOTARY_FEES_LABELS from '../config/notaryFees'
import PAYMENT_LABELS from '../config/payment'
import { PTZ_LABELS, PTZ_MATCHING_VALUES } from '../config/ptz'
import TAEG_LABELS from '../config/taeg'

import { MONTH_PER_YEAR, PERCENT_FACTOR, ROUND_VALUE } from './constantValues'
import { toLocale } from './helpers'
import { print } from './print'

const PRINT_ID = 'printArea'
const WITH_DECIMAL = true

const MiniForm = memo(({ fields, loading, numberSteps, onPrevious, onSubmit, step, title, values }) => {
  const currentValues = {}
  fields.map(({ key }) => Object.assign(currentValues, { [key]: !isNil(values[key]) ? values[key] : '' }))
  const formProps = {
    cta: step < numberSteps ? 'Suivant' : 'Calculer',
    disabled: isValueEmpty(currentValues),
    fields,
    isLoading: loading,
    onPrevious,
    onSubmit,
    progress: numberSteps > 1 ? { max: numberSteps, value: step } : null,
    title,
  }
  return <MiniFormComponent {...formProps} />
})
MiniForm.propTypes = {
  fields: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  numberSteps: PropTypes.number.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  title: PropTypes.string,
  values: PropTypes.object.isRequired,
}
MiniForm.displayName = 'MiniForm'

export const getProps = ({
  excerpt,
  id,
  fields,
  formTitle,
  isResultsOpen,
  loading,
  numberSteps,
  onPrevious,
  onReset,
  onSubmit,
  onToggleResults,
  resultComponent,
  results,
  resultsOnModal,
  sentencesProps,
  simulator,
  simulators,
  step,
  title,
  values,
}) => {
  const miniFormProps = {
    fields,
    loading,
    numberSteps,
    onPrevious,
    onSubmit,
    step,
    title: formTitle,
    values,
  }
  return {
    decorationPath: SIMULATORS_IMAGES[simulator.type],
    description: excerpt,
    form: <MiniForm {...miniFormProps} />,
    formQuantity: numberSteps - step,
    isInformationDisplayed: simulator.informationDisplay,
    isResultsOpen,
    isCalculatorsSectionDisplayed: !!simulator.isCalculatorsSectionDisplayed,
    isSimulatorDisplayed: !!simulator.displaySimulator,
    loading,
    onReset,
    onToggleResults,
    relatedInformation: [
      {
        ...simulator.information.information1,
        buttonText: 'En savoir plus',
        type: 'emoji',
      },
      {
        ...simulator.information.information2,
        buttonText: 'En savoir plus',
        type: 'emoji',
      },
      {
        ...simulator.information.information3,
        type: 'avatar',
      },
    ],
    resultComponent,
    results,
    resultsOnModal,
    sentencesProps: fields.length === 0 ? sentencesProps : null,
    simulators: formatSimulators(simulators.nodes, [id]),
    simulatorsSectionTitle: simulator.displaySimulator
      ? LABELS.simulatorsSectionTitle
      : LABELS.onlySimulatorsSectionTitle,
    title,
    values,
  }
}
const getValuesFromType = (key, value) => {
  const field = FIELDS[key]
  const { shortTitle, title, type, hideCard } = field
  if (hideCard) return null
  const keys = { title: shortTitle || title }
  switch (type) {
    case 'scale':
    case 'selectfield':
      Object.assign(keys, { value: field.props.options.find(opt => opt.value === value).label })
      break
    case 'numberfield':
      Object.assign(keys, { value: toLocale(value, field.props.suffix) })
      break
    case 'city':
      Object.assign(keys, { value: value.city })
      break

    default:
      break
  }
  return keys
}
const createCards = (values, onReset, type) =>
  Object.entries(values)
    .map(([key, value], index) => {
      const onClick = () => onReset(type, key)
      const item = getValuesFromType(key, value)
      if (item) return { ...item, autoFocus: index === 0, icon: 'pen', onClick }
      return null
    })
    .filter(Boolean)

const getPath = (values, type, projectKind) => {
  let path
  switch (type) {
    case 'notaryFees': {
      const { localisation, propertyKind, purchasePrice } = values
      const valuesForPath = {
        'good.localisation': localisation,
        'good.property_kind': propertyKind,
        'purchase.property_price': purchasePrice,
      }
      path = objToPath(valuesForPath, projectKind)
      break
    }

    case 'taeg': {
      const { duration, principal, rate } = values
      const valuesForPath = {
        'extra.mortgageEndDate': temporal().add(duration, 'y').format('DD / MM / YYYY'),
        'renegotiation.rate': rate,
        'renegotiation.remaining_principal': principal,
      }
      path = objToPath(valuesForPath, projectKind)
      break
    }
    case 'schedule': {
      const { principal, rate, duration } = values
      const valuesForPath = {
        'extra.mortgageEndDate': temporal().add(duration, 'y').format('DD / MM / YYYY'),
        'renegotiation.rate': rate,
        'renegotiation.remaining_principal': principal,
      }
      path = objToPath(valuesForPath, projectKind)
      break
    }

    case 'ptz': {
      const { contribution, income, kind, localisation, price, primo, residents, usage } = values
      const valuesForPath = {
        contribution,
        'good.localisation': localisation,
        'good.property_kind': PTZ_MATCHING_VALUES[kind] ?? kind,
        'good.usage': usage,
        'mortgagors.0.housing': primo === 'true' ? 'tenant' : 'owner',
        'profile.fiscal_income_nm2': income,
        'purchase.property_price': price,
      }
      if (residents === 1) Object.assign(valuesForPath, { 'extra.hasComortgagor': 'false', 'profile.children': '0' })
      path = objToPath(valuesForPath, projectKind)
      break
    }

    default:
      path = 'https://app.staging.pretto.fr/'
      break
  }

  return path
}
const getRateStatus = (current, min, max) => {
  if (current < min) return 'success'
  if (current >= max) return 'error'
  return 'warning'
}
const goToLocation = (...args) => {
  const path = getPath(...args)
  trackAction('Simulator Results CTA Clicked')
  window.location.href = path
}
export const getResultComponent = ({
  id,
  informational,
  informationalError,
  onEdit,
  onReset,
  type,
  results,
  simulators,
  values,
  resultsOnModal,
}) => {
  const cards = createCards(values, onReset, type)
  const { informationSentence, simulatorsSectionTitle, subtitle } = LABELS
  const onPurchase = () => goToLocation(values, type, 'purchase')
  const onRenegotiate = () => goToLocation(values, type, 'renegotiation')
  const props = {
    cards,
    informationSentence,
    onEditSimulation: onEdit,
    onPurchase,
    onRenegotiate,
    renegotiationCta: informational.ctaRenegotiation,
    resultsOnModal,
    savingsCta: informational.cta,
    savingsDescription: informational.content,
    showEditSimulationButton: resultsOnModal,
    simulators: formatSimulators(simulators.nodes, [id]),
    simulatorsSectionTitle,
    subtitle,
  }
  switch (type) {
    case 'notaryFees': {
      const { contentTitle, tableTitle, title } = NOTARY_FEES_LABELS
      const elements = ['taxes', 'emoluments', 'emolumentsDebours', 'total']
      const formattedResults = elements.map(elem => {
        const value = results[elem]
        const amount = toLocale(value, '€', WITH_DECIMAL)
        const repartition = toLocale((value / results.total) * ROUND_VALUE, '%', WITH_DECIMAL)
        const title = NOTARY_FEES_LABELS[elem]
        if (elem === 'total') return { amount, title }
        return { amount, repartition, title }
      })
      const highlight = toLocale(results.total, '€', WITH_DECIMAL)
      const componentProps = {
        ...props,
        highlight,
        highlights: [{ title: contentTitle, value: highlight }],
        results: formattedResults,
        tableTitle,
        title,
      }
      return <NotaryFeesResultsPage {...componentProps} />
    }
    case 'taeg': {
      const { title } = TAEG_LABELS
      const formattedResults = [
        { title: FIELDS.insuranceRate.title, value: toLocale(values.insuranceRate, '%') },
        { title: TAEG_LABELS.insuranceCost, value: toLocale(results.insuranceCost, '€') },
      ]
      const componentProps = {
        ...props,
        highlight: toLocale(results.taeg, '%'),
        highlights: [
          { title: TAEG_LABELS.taeg, value: toLocale(results.taeg, '%') },
          { title: TAEG_LABELS.payment, value: toLocale(results.payment, '€') },
          { title: TAEG_LABELS.totalCost, value: toLocale(results.totalCost, '€') },
        ],
        results: formattedResults,
        title,
      }
      return <TaegResultsPage {...componentProps} />
    }

    case 'payment': {
      const { duration, insuranceRate, principal, rate } = values
      const { title, tableTitle } = PAYMENT_LABELS
      const { insurancePayment, payment, table, totalCost: creditCost } = results
      const insuranceCost = insurancePayment * duration * MONTH_PER_YEAR
      const totalCost = creditCost + insuranceCost
      const totalPayment = payment + insurancePayment
      const tableHead = {
        insurance: 'Assurance',
        interests: 'Intérêts',
        month: 'Mois',
        payment: 'Mensualité',
        remainingPrincipal: 'Capital restant',
        repaidPrincipal: 'Capital remboursé',
      }
      const tableRows = table.map(
        ({ insurancePayment, interests, rank, remainingPrincipal, repaidPrincipal, totalPayment }) => ({
          insurance: toLocale(insurancePayment, '€', WITH_DECIMAL),
          interests: toLocale(interests, '€', WITH_DECIMAL),
          month: rank,
          payment: toLocale(totalPayment, '€', WITH_DECIMAL),
          remainingPrincipal: toLocale(remainingPrincipal, '€', WITH_DECIMAL),
          repaidPrincipal: toLocale(repaidPrincipal, '€', WITH_DECIMAL),
        })
      )
      const onDownload = () => {
        print(PRINT_ID)
      }
      const componentProps = {
        ...props,
        downloadCta: PAYMENT_LABELS.download,
        hideCta: PAYMENT_LABELS.hide,
        highlight: toLocale(totalPayment, '€'),
        highlights: [
          { title: PAYMENT_LABELS.totalPayment, value: toLocale(totalPayment, '€') },
          { title: PAYMENT_LABELS.mortgagePayment, value: toLocale(payment, '€') },
          {
            comment: `(dont ${toLocale(insuranceCost, '€')} d'assurance)`,
            title: PAYMENT_LABELS.totalCost,
            value: toLocale(totalCost, '€'),
          },
        ],
        onDownload,
        results: [tableHead, ...tableRows],
        showCta: PAYMENT_LABELS.show,
        subtitle: `Cette mensualité inclut une part d'assurance de ${toLocale(
          insurancePayment,
          '€'
        )}. Consultez les details ci-dessous`,
        tableTitle,
        title,
      }
      const printData = {
        duration,
        insuranceRate,
        path: getPath(values, type, 'purchase'),
        payment,
        principal,
        rate,
        tableHead,
        tableRows,
        totalCost,
      }
      return (
        <Fragment>
          <PaymentResultsPage {...componentProps} />
          <PrintPage id={PRINT_ID} data={printData} />
        </Fragment>
      )
    }

    case 'ptz': {
      const { title } = PTZ_LABELS
      const { amount, duration, franchise, isEligible, mainLoanAmount, payment, rates, smoothedPayment } = results
      const highlights = isEligible
        ? [
            { title: PTZ_LABELS.mainLoanAmount, value: toLocale(mainLoanAmount + amount, '€') },
            { title: PTZ_LABELS.amount, value: toLocale(amount, '€') },
            { title: LABELS.payment, value: toLocale(payment, '€') },
          ]
        : rates.map(({ duration, rate }) => ({ title: `Taux sur ${duration} ans`, value: toLocale(rate, '%') }))
      const componentProps = {
        ...props,
        highlights,
        isEligible,
        title: isEligible ? title : PTZ_LABELS.nonEligibleTitle,
      }
      if (isEligible) {
        const formattedResults = [
          { title: PTZ_LABELS.duration, value: toLocale(duration, 'ans') },
          { title: PTZ_LABELS.franchise, value: toLocale(franchise, 'ans') },
          { title: PTZ_LABELS.smoothedPayment, value: toLocale(smoothedPayment, '€') },
        ]
        Object.assign(componentProps, {
          highlight: toLocale(amount, '€'),
          results: formattedResults,
        })
      } else {
        Object.assign(componentProps, {
          emoji: ':thinking:',
          savingsCta: informationalError.cta,
          savingsDescription: informationalError.content,
          subtitle: 'Pas de panique, découvrez le taux dont vous pouvez bénéficier avec Pretto.',
        })
      }
      return <PtzResultsPage {...componentProps} />
    }

    case 'loanSmoothing': {
      const { smoothedPayment, shortSchedule, longSchedule, shortLoanPayment, paymentsTable } = results
      const { longLoanDuration, longLoanRate, shortLoanDuration, shortLoanRate } = values

      const rows = paymentsTable.reduce(
        (previous, [label, short, long]) => {
          return {
            long: [...previous.long, { label, value: toLocale(long, '€', WITH_DECIMAL) }],
            short: [...previous.short, { label, value: toLocale(short, '€', WITH_DECIMAL) }],
          }
        },
        {
          long: [],
          short: [],
        }
      )

      const firstPhase = {
        rows: rows.short,
        title: `Période 1 (mois 1 à ${shortSchedule.table.length})`,
      }
      const secondPhase = {
        rows: rows.long,
        title: `Période 2 (mois ${shortSchedule.table.length + 1} à ${longSchedule.table.length})`,
      }

      const loanDurationError = shortLoanDuration >= longLoanDuration && 'loanDurationError'
      const paymentError = shortLoanPayment > smoothedPayment && 'paymentError'
      const errors = [paymentError, loanDurationError]
      const error = errors.some(Boolean)
      const errorStatus = errors.find(Boolean)

      const errorText = {
        loanDurationError:
          'Vous avez renseigné une durée de prêt court plus longue que la durée de votre prêt long. Nous vous invitons à modifier votre simulation.',
        paymentError:
          'La mensualité de votre prêt court est trop élevée pour pouvoir lisser votre prêt long dessus. Cela signifie que le montant de votre prêt court est trop élevé ou la durée est trop faible par rapport à votre prêt long.',
      }

      const componentProps = {
        ...props,
        error,
        highlight: toLocale(smoothedPayment, '€'),
        longSchedule: secondPhase,
        results,
        shortSchedule: firstPhase,
        showEditSimulationButton: error,
        title: 'Votre mensualité lissée : ',
      }

      if (error) {
        const shortSchedule = {
          rows: [
            { label: 'Taux', value: toLocale(shortLoanRate, '%') },
            { label: 'Durée', value: toLocale(shortLoanDuration, 'mois') },
          ],
          title: `Votre prêt court`,
        }
        const longSchedule = {
          rows: [
            { label: 'Taux', value: toLocale(longLoanRate, '%') },
            { label: 'Durée', value: toLocale(longLoanDuration, 'mois') },
          ],
          title: `Votre prêt long`,
        }

        Object.assign(componentProps, {
          emoji: ':thinking:',
          highlight: null,
          longSchedule,
          savingsCta: informationalError.cta,
          savingsDescription: informationalError.content,
          shortSchedule,
          subtitle: errorText[errorStatus],
          title: 'Oups, nous ne pouvons pas lisser vos mensualités',
        })
      }
      return <LoanSmoothingPage {...componentProps} />
    }

    case 'pinel': {
      const { isEligible } = results

      if (!isEligible) {
        const { isFurnitureValid, isPropertyKindValid, isZoneValid } = results
        const componentProps = {
          ...props,
          emoji: ':thinking:',
          error: !isEligible,
          isFurnitureValid,
          isPropertyKindValid,
          isZoneValid,
          subtitle: 'Retrouvez les explications ci-dessous.',
          title: 'Oups, il semblerait que vous ne soyez pas éligible au dispositif Pinel',
        }

        return <PinelPage {...componentProps} />
      }

      const { results: table, maximumRent, payment } = results

      const savings = table.reduce(
        (
          previous,
          { duration, reductionRatio, totalFinancialAdvances, totalReductionAmount, financialAdvances, reductionAmount }
        ) => {
          const prefixSign = financialAdvances > 0 ? '+' : ''
          return [
            ...previous,
            [
              toLocale(duration, 'ans'),
              toLocale(payment, '€'),
              toLocale(maximumRent, '€'),
              toLocale(reductionRatio, '%'),
              toLocale(reductionAmount, '€'),
              toLocale(totalReductionAmount, '€'),
              `${prefixSign}${toLocale(financialAdvances, '€')}`,
              `${prefixSign}${toLocale(totalFinancialAdvances, '€')}`,
            ],
          ]
        },
        [
          [
            '',
            'Mensualité',
            'Loyer',
            <span key="taxesReduction">
              Réduction impôts <sup>(1)</sup>
            </span>,
            <span key="amountTaxesReduction">
              Montant déduction impôts <sup>(2)</sup>
            </span>,
            'Montant total',
            <span key="extraCash">
              Excédent ou besoin de trésorerie <sup>(3)</sup>
            </span>,
            'Montant total',
          ],
        ]
      )

      const componentProps = {
        ...props,
        error: !isEligible,
        maximumRent: toLocale(maximumRent, '€'),
        payment: toLocale(payment, '€'),
        results,
        savings,
        title: 'Bonne nouvelle, vous êtes éligible au dispositif Pinel !',
      }

      return <PinelPage {...componentProps} />
    }

    case 'indebtednessRate': {
      const { currentIndebtednessRate, errorRate, successRate, remaining, totalIncome } = results

      const error = totalIncome <= 0

      if (error) {
        const componentProps = {
          ...props,
          emoji: ':thinking:',
          error,
          savingsCta: informationalError.cta,
          savingsDescription: informationalError.content,
          subtitle: 'Retrouvez les explications ci-dessous.',
          title: 'Oups, le calcul de votre taux d’endettement semble impossible',
        }

        return <IndebtednessRatePage {...componentProps} />
      }

      const componentProps = {
        ...props,
        highlight: toLocale(currentIndebtednessRate * PERCENT_FACTOR, '%'),
        indebtednessRate: currentIndebtednessRate,
        maxIndebtednessRate: errorRate,
        remaining,
        status: getRateStatus(currentIndebtednessRate, successRate, errorRate),
        title: 'Votre taux d’endettement :',
        warning: errorRate !== successRate,
      }

      return <IndebtednessRatePage {...componentProps} />
    }

    case 'prepayment': {
      const {
        closeDuration,
        closePayment,
        closeSavings,
        duration,
        error,
        ira,
        newPayment,
        newPrincipal,
        payment,
        refundAmount,
        refundDate,
        remainingDuration,
        remainingPrincipal,
        savings,
      } = results

      const title = 'Votre remboursement anticipé'

      if (error) {
        const componentProps = {
          ...props,
          error,
          title,
        }

        return <PrepaymentPage {...componentProps} />
      }

      const negativePrincipalError = remainingPrincipal < 0 && 'negativePrincipal'
      const refundedError = newPrincipal < 0 && 'refunded'
      const errors = [negativePrincipalError, refundedError]

      const errorStatus = errors.find(Boolean)

      const componentProps = {
        ...props,
        closeDuration,
        closePayment,
        closeSavings,
        duration,
        error: errorStatus,
        ira,
        newPayment,
        newPrincipal,
        payment,
        refundAmount,
        refundDate,
        remainingDuration,
        remainingPrincipal,
        savings,
        title,
      }

      return <PrepaymentPage {...componentProps} />
    }

    default:
      break
  }
}
